<template>
  <div>
    <NuxtLayout
      name="teasers"
      v-bind="{ loading }"
      :no-results="!teasers.length"
      @refresh="refetch"
    >
      <LotteryTeaser
        v-for="(teaser, index) in teasers"
        :key="index"
        v-bind="{
          ...teaser,
          licensedTerritory,
        }"
        @click="handleTeaserClick(teaser)"
        @update-teasers="refetch"
      />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import {
  KnownProductType,
  type LotteryTeaserPayload,
} from '~/@types/generated/backend/graphql-schema-types'

type PlayNowLotteriesProps = {
  pollInterval?: number
}

const props = withDefaults(defineProps<PlayNowLotteriesProps>(), {
  pollInterval: 3_000_000,
})

const licensedTerritory = useLicensedTerritory({ raw: true })

const { loading, refetch, result } = useDefaultTeasersQuery(
  { licensedTerritory, productType: KnownProductType.Lottery },
  {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: props.pollInterval,
  },
)

const teasers = computed(
  () => (result.value?.defaultTeasers.teasers ?? []) as LotteryTeaserPayload[],
)

const { onResult } = useDrawDateChangesSubscription({ licensedTerritory })
onResult(() => refetch())

const TRACKING_LIST_NAME = 'Play Now Lottery Teasers'
const TRACKING_LIST_ID = 'play_now_lottery_teasers'

const { createTeaserListItems } = useTrackingData()
const { pushSelectListItemEvent, pushViewListEvent } = useTracking()
const { logTrackingError } = useTrackingErrorLog()

const handleTeaserClick = (teaser: LotteryTeaserPayload) => {
  try {
    pushSelectListItemEvent({
      items: createTeaserListItems([teaser]),
      list_id: TRACKING_LIST_ID,
      list_name: TRACKING_LIST_NAME,
    })
  } catch (err) {
    logTrackingError('select_list_item', err)
  }
}

let unwatchTeasers: (() => void) | null = null
unwatchTeasers = watchImmediate(teasers, (list) => {
  if (!list || list.length === 0) return

  try {
    pushViewListEvent({
      items: createTeaserListItems(list),
      list_id: TRACKING_LIST_ID,
      list_name: TRACKING_LIST_NAME,
    })

    if (unwatchTeasers) unwatchTeasers()
  } catch (err) {
    logTrackingError('view_list', err)
  }
})
</script>
